<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页
          </el-breadcrumb-item>
          <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
          <el-breadcrumb-item>门店列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="admin_main_block_top">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item lable="ID">
              <el-input
                v-model="where.merchantId"
                placeholder="门店ID/用户ID/商家ID/bd ID"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                v-model="where.keyWord"
                placeholder="门店名称/地址/营业电话"
              ></el-input>
            </el-form-item>
            <!--            <el-form-item label="DB手机号">-->
            <!--              <el-input-->
            <!--                  v-model="where.bdAccount"-->
            <!--                  placeholder="BD手机号"-->
            <!--              ></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="经营类型">-->
            <!--              <el-select v-model="where.type" placeholder="选择类型">-->
            <!--                <el-option label="全部" value=""></el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item label="加入时间">
              <el-date-picker
                v-model="date"
                @change="getDate" @input="immediUpdate"
                type="daterange"
                range-separator="~"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                start-placeholder="起始日期"
                end-placeholder="截止日期"
              >
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                plain
                icon="el-icon-search"
                @click="getGoodsList()"
                >条件筛选</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                plain
                icon="el-icon-search"
                @click="reset()"
                >重置
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <div>
            <el-button type="primary" icon="el-icon-tickets"
              >导出数据
            </el-button>
          </div>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          @selection-change="handleSelectionChange"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="shopId" label="门店ID"></el-table-column>
          <el-table-column prop="merchantName" label="商家名称"></el-table-column>
          <!--          <el-table-column-->
          <!--            prop="merchantPhone"-->
          <!--            label="商家手机号"-->
          <!--          ></el-table-column>-->
          <!--          <el-table-column prop="bdUserId" label="开发者ID"></el-table-column>-->
          <!--          <el-table-column prop="payCardNo" label="收款码编号">-->
          <!--            <template slot-scope="scope">-->
          <!--              <span v-if="scope.row.payCardNoList.length > 0">-->
          <!--                <el-row>-->
          <!--                  <el-col-->
          <!--                    v-for="(rs, index) in scope.row.payCardNoList"-->
          <!--                    :key="index"-->
          <!--                    >{{ rs }}</el-col-->
          <!--                  >-->
          <!--                </el-row>-->
          <!--              </span>-->
          <!--              <span v-else>{{ scope.row.payCardNo }}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column prop="shopName" label="门店名称"></el-table-column>
          <el-table-column
            prop="categoryName"
            label="经营类型"
          ></el-table-column>
          <el-table-column
            prop="shopAreaCode"
            label="所属区域"
          ></el-table-column>
          <el-table-column
            prop="givingPercent"
            label="赠豆比例"
          ></el-table-column>
          <el-table-column prop="nickName" label="BD姓名"></el-table-column>
          <el-table-column prop="account" label="BD手机号"></el-table-column>
          <el-table-column prop="isOpenBeanPay" label="是否开启悦豆支付">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isOpenBeanPay == 1">
                开启
              </el-tag>
              <el-tag type="danger" v-if="scope.row.isOpenBeanPay == 0">
                关闭
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="businessPhone"
            label="营业电话"
          ></el-table-column>
          <el-table-column prop="applyDate" label="加入时间"></el-table-column>
          <!--          <el-table-column prop="lastTradeTime" label="最后交易时间"></el-table-column>-->
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.state == 1"
                >{{ scope.row.stateText }}
              </el-tag>
              <el-tag type="warning" v-else-if="scope.row.state == 2"
                >{{ scope.row.stateText }}
              </el-tag>
              <el-tag type="danger" v-else-if="scope.row.state == 0"
                >{{ scope.row.stateText }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right" width="120px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link"
                  >操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-edit">
                    <router-link :to="{ name: 'shopEdit', query: scope.row }">
                      门店修改
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item icon="el-icon-postcard">
                    <router-link :to="{ name: 'shopdetail', query: scope.row }">
                      门店信息
                    </router-link>
                  </el-dropdown-item>
                  <!--                  <el-dropdown-item icon="el-icon-notebook-2">门店列表-->
                  <!--                  </el-dropdown-item>-->
                  <!--                  <el-dropdown-item disabled icon="el-icon-menu">收款二维码-->
                  <!--                  </el-dropdown-item>-->
                  <!--                  <el-dropdown-item disabled icon="el-icon-s-shop">店长信息-->
                  <!--                  </el-dropdown-item>-->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../../../router";

// import datePicker from "@/components/admin/date-picker";

export default {
  components: {
    // datePicker
  },
  data() {
    return {
      list: [],
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      loading: false,
       date: "",
      where: {
        // createDateEnd: "",
        // createDateStart: "",
        keyWord: "",
        // categoryCode: "",
        merchantId:"",
        pageNum: 0,
        page: 1,
        pageSize: 20,
        // shopId: 0,
        // shopAreaCode: this.$route.query.area_code,
        // bdServiceId: this.$route.query.user_id,
        // bdUserId: this.$route.query.bdUserId,
      },
    };
  },
  methods: {
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    getGoodsList() {
      this.loading = true;
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      // this.where.merchantId = this.$route.query.id;
      this.$post(this.$api.systemShopList, this.where).then((res) => {
        this.where.page = res.data.current;
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    reset() {
      this.where = {
        pageNum: 0,
        page: 1,
        pageSize: 20,
      };
      this.getGoodsList();
    },
    getDate(obj) {
      if (obj) {
        this.where.createDateStart = obj[0];
        this.where.createDateEnd = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.createDateStart = '';
          this.where.createDateEnd = '';
        }
      });
    },
    shoplist() {
      router.push("/Admin/merchant/merchant/merchantlist");
    },
    currentChange(e) {
      this.currentPage = e;
      this.getGoodsList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getGoodsList();
    },
  },
  created() {
    this.getGoodsList();
  },
};
</script>
